import React, { useState, useEffect } from "react";
import { ModelViewer } from "./ModelViewer";
import { useParams } from "react-router-dom";

const ModelGallery = ({ bucketUrl }) => {
  const { model_id } = useParams();
  const [models, setModels] = useState([
    {
      id: "1",
      name: "Cupcake",
      url: "https://augmented-reality-app-v1.s3.us-east-1.amazonaws.com/models/11_30_2024.glb",
    },
    {
      id: "2",
      name: "Pizza",
      url: "https://augmented-reality-app-v1.s3.us-east-1.amazonaws.com/models/pizza.glb",
    },
    {
      id: "3",
      name: "Bread",
      url: "https://augmented-reality-app-v1.s3.us-east-1.amazonaws.com/models/Sliced+sourdough+bread+on+board.glb",
    },
  ]);

  console.log(model_id);

  const selectedModel = models.find((item) => item.id === model_id);

  if (!selectedModel) {
    return (
      <div className="flex h-screen items-center justify-center">
        <p className="text-lg text-gray-600">Model not found</p>
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      <ModelViewer
        modelPath={selectedModel.url}
        backgroundColor={0xffffff}
        showGround={false}
        groundColor={0xeeeeee}
        autoRotate={false}
      />
    </div>
  );
};

export default ModelGallery;

import React, { useEffect, useState } from "react";
import FoodItem from "./FoodItem";
import Skeleton from "./Skeleton";
import image1 from "../../assets/1.png";

const Foods = () => {
  const [menuTab, setMenuTab] = useState("Breakfast");
  const [loading, setLoading] = useState(false);
  //   const [foods] = useFetch();

  const foods = [
    {
      id: 1,
      title: "Cupcake",
      description:
        "Demesne mention promise you justice arrived way.Amazing foods are or and increasing to in especially inquietude companions acceptance admiration.Outweigh it families distance wandered ye..",
      price: 8.99,
      image: { image1 },
      type: "Breakfasts",
      url: "https://augmented-reality-app-v1.s3.us-east-1.amazonaws.com/models/11_30_2024.glb",
    },
    {
      id: 2,
      title: "Pizza",
      description:
        "Demesne mention promise you justice arrived way.Amazing foods are or and increasing to in especially inquietude companions acceptance admiration.Outweigh it families distance wandered ye..",
      price: 10.99,
      image: "src/assets/1.png",
      type: "Breakfast",
      url: "https://augmented-reality-app-v1.s3.us-east-1.amazonaws.com/models/pizza.glb",
    },
    {
      id: 3,
      title: "Bread",
      description:
        "Demesne mention promise you justice arrived way.Amazing foods are or and increasing to in especially inquietude companions acceptance admiration.Outweigh it families distance wandered ye..",
      price: 3.99,
      image: "../../assets/1.png",
      type: "Breakfast",
      url: "https://augmented-reality-app-v1.s3.us-east-1.amazonaws.com/models/Sliced+sourdough+bread+on+board.glb",
    },
  ];

  //loading
  //   useEffect(() => {
  //     setLoading(true);
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 1000);
  //   }, []);

  //food menu tab
  const handleMenuTabs = (type) => {
    setMenuTab(type);
  };

  return (
    <section className="my-12 max-w-screen-xl mx-auto px-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-12">
        {foods.map(
          (item) => (
            // loading ? (
            //   <Skeleton key={item._id} />
            // ) : (
            <FoodItem key={item.id} {...item} />
          )
          // )
        )}
      </div>
    </section>
  );
};

export default Foods;

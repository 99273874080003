import React from "react";
import Foods from "../components/Foods Showcase/Foods";
// import Banner from "../components/Header/Banner";

const HomeScreen = () => {
  return (
    <>
      {/* <Banner /> */}
      <Foods />
      {/* <AboutUs />
      <Footer /> */}
    </>
  );
};

export default HomeScreen;

import React from "react";
// import { ThreeScene } from "./components/ThreeScene";
import { ModelViewer } from "./components/ModelViewer";
import { useStore } from "./store";
import HomeScreen from "./screens/HomeScreen";
import ModelGallery from "./components/ModelGallery";
import { Routes, Route, Navigate } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/view-model/:model_id?" element={<ModelGallery />} />
      </Routes>
    </Router>
  );
};

export default App;

import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export const ModelViewer = ({
  modelPath,
  backgroundColor = 0xffffff,
  showGround = false,
  groundColor = 0xeeeeee,
  autoRotate = false,
}) => {
  const mountRef = useRef(null);
  const sceneRef = useRef(null);
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const controlsRef = useRef(null);
  const requestRef = useRef(null);
  const modelRef = useRef(null);
  const videoRef = useRef(null);
  const [hasCamera, setHasCamera] = useState(false);

  // Check for camera support
  useEffect(() => {
    const checkCameraSupport = async () => {
      try {
        // Check if mediaDevices API is supported
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
          console.log("MediaDevices API not supported");
          setHasCamera(false);
          return;
        }

        // Try to get camera permission
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setHasCamera(true);

        // Clean up the test stream
        stream.getTracks().forEach((track) => track.stop());
      } catch (err) {
        console.log("Camera not available:", err);
        setHasCamera(false);
      }
    };

    checkCameraSupport();
  }, []);

  useEffect(() => {
    if (!mountRef.current) return;

    const mount = mountRef.current;

    // Scene setup
    const scene = new THREE.Scene();

    // Create video element and texture for background
    const video = document.createElement("video");
    video.style.display = "none";
    video.playsInline = true;
    video.autoplay = true;
    video.muted = true;
    videoRef.current = video;
    document.body.appendChild(video);

    const videoTexture = new THREE.VideoTexture(video);
    videoTexture.minFilter = THREE.NearestFilter;
    videoTexture.magFilter = THREE.NearestFilter;
    videoTexture.generateMipmaps = false;
    videoTexture.format = THREE.RGBAFormat;

    // Create background scene
    const backgroundScene = new THREE.Scene();
    const backgroundCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
    const backgroundMaterial = new THREE.MeshBasicMaterial({
      map: videoTexture,
    });
    const backgroundGeometry = new THREE.PlaneGeometry(2, 2);
    const backgroundMesh = new THREE.Mesh(
      backgroundGeometry,
      backgroundMaterial
    );
    backgroundScene.add(backgroundMesh);

    // Camera setup
    const camera = new THREE.PerspectiveCamera(
      65,
      mount.clientWidth / mount.clientHeight,
      0.1,
      1000
    );
    camera.position.set(0, 2, 5);

    // Renderer setup
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    renderer.setSize(mount.clientWidth, mount.clientHeight);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    renderer.autoClear = false;
    mount.appendChild(renderer.domElement);

    // Lighting and scene setup remains the same
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.9);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(5, 5, 5);
    directionalLight.castShadow = true;
    directionalLight.shadow.mapSize.set(2048, 2048);
    directionalLight.shadow.normalBias = 0.05;
    directionalLight.shadow.camera.far = 20;
    scene.add(directionalLight);

    const fillLight = new THREE.DirectionalLight(0xffffff, 0.3);
    fillLight.position.set(-5, 0, -5);
    scene.add(fillLight);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.screenSpacePanning = false;
    controls.minDistance = 2;
    controls.maxDistance = 20;
    controls.maxPolarAngle = Math.PI / 2;
    controls.autoRotate = autoRotate;
    controls.autoRotateSpeed = 1.0;

    if (showGround) {
      const groundGeometry = new THREE.PlaneGeometry(10, 10);
      const groundMaterial = new THREE.MeshStandardMaterial({
        color: groundColor,
        roughness: 0.8,
        metalness: 0.2,
      });
      const ground = new THREE.Mesh(groundGeometry, groundMaterial);
      ground.rotation.x = -Math.PI / 2;
      ground.position.y = -0.001;
      ground.receiveShadow = true;
      scene.add(ground);
    }

    // Start camera if available
    const startCamera = async () => {
      if (
        hasCamera &&
        navigator.mediaDevices &&
        navigator.mediaDevices.getUserMedia
      ) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: {
              facingMode: { ideal: "environment" },
              width: { ideal: 1920 },
              height: { ideal: 1080 },
            },
          });

          video.srcObject = stream;
          await video.play();
        } catch (err) {
          console.error("Error starting camera:", err);
          scene.background = new THREE.Color(backgroundColor);
        }
      } else {
        scene.background = new THREE.Color(backgroundColor);
      }
    };

    startCamera();

    // Load GLTF Model
    const loader = new GLTFLoader();
    loader.load(
      modelPath,
      (gltf) => {
        const model = gltf.scene;
        model.traverse((node) => {
          if (node.isMesh) {
            node.castShadow = true;
            node.receiveShadow = true;
            if (node.material) {
              node.material.roughness = Math.min(
                node.material.roughness || 0.7,
                0.9
              );
              node.material.metalness = Math.min(
                node.material.metalness || 0.3,
                0.7
              );
            }
          }
        });

        const box = new THREE.Box3().setFromObject(model);
        const center = box.getCenter(new THREE.Vector3());
        const size = box.getSize(new THREE.Vector3());
        const maxDim = Math.max(size.x, size.y, size.z);
        const scale = 2 / maxDim;
        model.scale.setScalar(scale);
        model.position.sub(center.multiplyScalar(scale));

        scene.add(model);
        modelRef.current = model;
      },
      (progress) => {
        console.log(
          "Loading progress:",
          (progress.loaded / progress.total) * 100 + "%"
        );
      },
      (error) => {
        console.error("Error loading model:", error);
      }
    );

    // Store refs
    sceneRef.current = scene;
    cameraRef.current = camera;
    rendererRef.current = renderer;
    controlsRef.current = controls;

    // Handle window resize
    const handleResize = () => {
      const width = mount.clientWidth;
      const height = mount.clientHeight;

      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };

    window.addEventListener("resize", handleResize);

    // Animation loop
    const animate = () => {
      controls.update();

      // Render background first
      if (hasCamera && video.readyState === video.HAVE_ENOUGH_DATA) {
        renderer.clear();
        renderer.render(backgroundScene, backgroundCamera);
        renderer.clearDepth();
      }

      // Render scene
      renderer.render(scene, camera);
      requestRef.current = requestAnimationFrame(animate);
    };

    requestRef.current = requestAnimationFrame(animate);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
      if (mount) {
        mount.removeChild(renderer.domElement);
      }
      if (video.srcObject) {
        const tracks = video.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
      if (video.parentNode) {
        video.parentNode.removeChild(video);
      }
      renderer.dispose();
      scene.traverse((object) => {
        if (object.geometry) {
          object.geometry.dispose();
        }
        if (object.material) {
          if (Array.isArray(object.material)) {
            object.material.forEach((material) => material.dispose());
          } else {
            object.material.dispose();
          }
        }
      });
    };
  }, [
    modelPath,
    backgroundColor,
    showGround,
    groundColor,
    autoRotate,
    hasCamera,
  ]);

  return <div ref={mountRef} className="w-full h-screen" />;
};
